// @ts-ignore
import locationIcon from "@/../fontawesome/map-location.svg"// @ts-ignore
import beachIcon from "@/../fontawesome/beach.svg"// @ts-ignore
import btsIcon from "@/../fontawesome/bts.svg"// @ts-ignore
import hospitalIcon from "@/../fontawesome/hospital.svg"// @ts-ignore
import mrtIcon from "@/../fontawesome/mrt.svg"// @ts-ignore
import graduationIcon from "@/../fontawesome/graduation.svg"// @ts-ignore
import shopIcon from "@/../fontawesome/shopping.svg"// @ts-ignore
import projectIcon from "@/../fontawesome/building-2.svg"// @ts-ignore
import airportIcon from '@/../fontawesome/airport.svg';//@ts-ignore
import golfIcon from '@/../fontawesome/golf.svg';//@ts-ignore
import trainIcon from '@/../fontawesome/train.svg';//@ts-ignore
// **Use in dynamic type place

const iconMap = {
  hospital: hospitalIcon,
  bts: btsIcon,
  school: graduationIcon,
  beach: beachIcon,
  mrt: mrtIcon,
  shopping: shopIcon,
  university: graduationIcon,
  project: projectIcon,
  airport: airportIcon,
  'golf-course': golfIcon,
  golf: golfIcon,
  train: trainIcon,
  tram: trainIcon,
  'airport-link': trainIcon,
};

export const getIconSearch = (type: string, subType: string) => {
  if (type === 'location') {
    return locationIcon;
  } else {
    return iconMap[subType] || null
  }
}

export const fetchIcon = async (type: string, subType: string) => {
  const icon = getIconSearch(type, subType);
  if (icon) {
    return fetch(icon)
      .then(response => response.text())
      .then(data => {
        let svg = data.replace(/fill="[^\"]*"/g, 'fill="#95a4b8"');
        return svg.replace('<svg', `<svg width="16" height="16" style="margin:auto"`);
      }).catch(error => console.error(error));
  }
  return '';
}

/**
 * Filter Meilisearch
 * */
export const getFilters = (
  countryCode: string,
  type: string,
) => {
  let filterStr = 'country = ' + countryCode;
  if (type && type.length) {
    if (type === 'province') {
      /** when select province */
      filterStr += ' AND type = location';
    } else if (type === 'project') {
      /** when select project */
      filterStr += ' AND type = project';
    } else if (type === 'no-province') {
      /** when select location */
      filterStr += ' AND type = location AND sub_type != province';
    } else if(type === 'review') {
      /** use for review component */
      filterStr += ' AND type IN [location, project] AND sub_type != province';
    } else if(type === 'question') {
      /** use for question&answer component */
      filterStr += ' AND type IN [location, project]';
    } else {
      // case debounce search (when typing) send event in first parameter it's object
      filterStr += ' AND type = place AND sub_type = ' + type;
    }
  }
  //console.log(filterStr)
  return filterStr;
}

